export default {
  v: '5.9.4',
  fr: 25,
  ip: 0,
  op: 78,
  w: 379,
  h: 379,
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 37,
              s: [-180]
            },
            { t: 78, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 37,
              s: [201.5, 319.5, 0],
              to: [0, -3, 0],
              ti: [0, 3, 0]
            },
            { t: 79, s: [201.5, 301.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 40,
              s: [0, 0, 100]
            },
            { t: 46, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.102, -0.022],
                    [0, 0],
                    [0, 0],
                    [0.353, -0.353],
                    [0.096, -0.485],
                    [0, 0],
                    [0.08, -0.066],
                    [0.104, 0],
                    [0.08, 0.066],
                    [0.019, 0.101],
                    [0, 0],
                    [0.353, 0.353],
                    [0.492, 0.102],
                    [0, 0],
                    [0.065, 0.08],
                    [0, 0.102],
                    [-0.065, 0.08],
                    [-0.102, 0.022],
                    [0, 0],
                    [-0.353, 0.353],
                    [-0.096, 0.485],
                    [0, 0],
                    [-0.08, 0.066],
                    [-0.104, 0],
                    [-0.08, -0.066],
                    [-0.019, -0.101],
                    [0, 0],
                    [-0.353, -0.353],
                    [-0.492, -0.102],
                    [0, 0],
                    [-0.065, -0.08],
                    [0, -0.102],
                    [0.065, -0.08]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.492, 0.102],
                    [-0.353, 0.353],
                    [0, 0],
                    [-0.019, 0.101],
                    [-0.08, 0.066],
                    [-0.104, 0],
                    [-0.08, -0.066],
                    [0, 0],
                    [-0.096, -0.485],
                    [-0.353, -0.353],
                    [0, 0],
                    [-0.102, -0.022],
                    [-0.065, -0.08],
                    [0, -0.102],
                    [0.065, -0.08],
                    [0, 0],
                    [0.492, -0.102],
                    [0.353, -0.353],
                    [0, 0],
                    [0.019, -0.101],
                    [0.08, -0.066],
                    [0.104, 0],
                    [0.08, 0.066],
                    [0, 0],
                    [0.096, 0.485],
                    [0.353, 0.353],
                    [0, 0],
                    [0.102, 0.022],
                    [0.065, 0.08],
                    [0, 0.102],
                    [-0.065, 0.08]
                  ],
                  v: [
                    [3.641, 0.439],
                    [2.627, 0.645],
                    [2.627, 0.645],
                    [1.332, 1.342],
                    [0.645, 2.627],
                    [0.439, 3.641],
                    [0.286, 3.899],
                    [0, 4],
                    [-0.285, 3.899],
                    [-0.439, 3.641],
                    [-0.645, 2.627],
                    [-1.332, 1.342],
                    [-2.627, 0.645],
                    [-3.641, 0.439],
                    [-3.899, 0.282],
                    [-4, 0],
                    [-3.899, -0.282],
                    [-3.641, -0.439],
                    [-2.627, -0.645],
                    [-1.332, -1.342],
                    [-0.645, -2.627],
                    [-0.439, -3.641],
                    [-0.285, -3.899],
                    [0, -4],
                    [0.286, -3.899],
                    [0.439, -3.641],
                    [0.645, -2.627],
                    [1.332, -1.342],
                    [2.627, -0.645],
                    [3.641, -0.439],
                    [3.899, -0.282],
                    [4, 0],
                    [3.899, 0.282]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 37,
      op: 1037,
      st: 37,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 47,
              s: [-90]
            },
            { t: 78, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 47,
              s: [65.5, 306.5, 0],
              to: [0, -8.167, 0],
              ti: [0, 8.167, 0]
            },
            { t: 79, s: [65.5, 257.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 53,
              s: [0, 0, 100]
            },
            { t: 59, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.076, -0.016],
                    [0, 0],
                    [0, 0],
                    [0.264, -0.264],
                    [0.072, -0.364],
                    [0, 0],
                    [0.06, -0.049],
                    [0.078, 0],
                    [0.06, 0.049],
                    [0.014, 0.076],
                    [0, 0],
                    [0.264, 0.264],
                    [0.369, 0.077],
                    [0, 0],
                    [0.049, 0.06],
                    [0, 0.077],
                    [-0.049, 0.06],
                    [-0.076, 0.016],
                    [0, 0],
                    [-0.264, 0.264],
                    [-0.072, 0.364],
                    [0, 0],
                    [-0.06, 0.049],
                    [-0.078, 0],
                    [-0.06, -0.049],
                    [-0.014, -0.076],
                    [0, 0],
                    [-0.264, -0.264],
                    [-0.369, -0.077],
                    [0, 0],
                    [-0.049, -0.06],
                    [0, -0.077],
                    [0.049, -0.06]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.369, 0.077],
                    [-0.264, 0.264],
                    [0, 0],
                    [-0.014, 0.076],
                    [-0.06, 0.049],
                    [-0.078, 0],
                    [-0.06, -0.049],
                    [0, 0],
                    [-0.072, -0.364],
                    [-0.264, -0.264],
                    [0, 0],
                    [-0.076, -0.016],
                    [-0.049, -0.06],
                    [0, -0.077],
                    [0.049, -0.06],
                    [0, 0],
                    [0.369, -0.077],
                    [0.264, -0.264],
                    [0, 0],
                    [0.014, -0.076],
                    [0.06, -0.049],
                    [0.078, 0],
                    [0.06, 0.049],
                    [0, 0],
                    [0.072, 0.364],
                    [0.264, 0.264],
                    [0, 0],
                    [0.076, 0.016],
                    [0.049, 0.06],
                    [0, 0.077],
                    [-0.049, 0.06]
                  ],
                  v: [
                    [2.731, 0.329],
                    [1.97, 0.483],
                    [1.97, 0.483],
                    [0.999, 1.006],
                    [0.483, 1.97],
                    [0.329, 2.73],
                    [0.214, 2.924],
                    [0, 3],
                    [-0.214, 2.924],
                    [-0.329, 2.73],
                    [-0.483, 1.97],
                    [-0.999, 1.006],
                    [-1.97, 0.483],
                    [-2.731, 0.329],
                    [-2.924, 0.211],
                    [-3, 0],
                    [-2.924, -0.211],
                    [-2.731, -0.329],
                    [-1.97, -0.483],
                    [-0.999, -1.006],
                    [-0.483, -1.97],
                    [-0.329, -2.73],
                    [-0.214, -2.924],
                    [0, -3],
                    [0.214, -2.924],
                    [0.329, -2.73],
                    [0.483, -1.97],
                    [0.999, -1.006],
                    [1.97, -0.483],
                    [2.731, -0.329],
                    [2.924, -0.211],
                    [3, 0],
                    [2.924, 0.211]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 47,
      op: 1047,
      st: 47,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 41,
              s: [-80]
            },
            { t: 78, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 41,
              s: [67.5, 105.5, 0],
              to: [0, -8.5, 0],
              ti: [0, 8.5, 0]
            },
            { t: 79, s: [67.5, 54.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 45,
              s: [0, 0, 100]
            },
            { t: 51, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.127, -0.027],
                    [0, 0],
                    [0, 0],
                    [0.441, -0.441],
                    [0.12, -0.607],
                    [0, 0],
                    [0.1, -0.082],
                    [0.13, 0],
                    [0.1, 0.082],
                    [0.024, 0.126],
                    [0, 0],
                    [0.441, 0.441],
                    [0.615, 0.128],
                    [0, 0],
                    [0.082, 0.1],
                    [0, 0.128],
                    [-0.082, 0.1],
                    [-0.127, 0.027],
                    [0, 0],
                    [-0.441, 0.441],
                    [-0.12, 0.607],
                    [0, 0],
                    [-0.1, 0.082],
                    [-0.13, 0],
                    [-0.1, -0.082],
                    [-0.024, -0.126],
                    [0, 0],
                    [-0.441, -0.441],
                    [-0.615, -0.128],
                    [0, 0],
                    [-0.082, -0.1],
                    [0, -0.128],
                    [0.082, -0.1]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.615, 0.128],
                    [-0.441, 0.441],
                    [0, 0],
                    [-0.024, 0.126],
                    [-0.1, 0.082],
                    [-0.13, 0],
                    [-0.1, -0.082],
                    [0, 0],
                    [-0.12, -0.607],
                    [-0.441, -0.441],
                    [0, 0],
                    [-0.127, -0.027],
                    [-0.082, -0.1],
                    [0, -0.128],
                    [0.082, -0.1],
                    [0, 0],
                    [0.615, -0.128],
                    [0.441, -0.441],
                    [0, 0],
                    [0.024, -0.126],
                    [0.1, -0.082],
                    [0.13, 0],
                    [0.1, 0.082],
                    [0, 0],
                    [0.12, 0.607],
                    [0.441, 0.441],
                    [0, 0],
                    [0.127, 0.027],
                    [0.082, 0.1],
                    [0, 0.128],
                    [-0.082, 0.1]
                  ],
                  v: [
                    [4.551, 0.549],
                    [3.284, 0.806],
                    [3.284, 0.806],
                    [1.665, 1.678],
                    [0.806, 3.284],
                    [0.549, 4.551],
                    [0.357, 4.873],
                    [0, 5],
                    [-0.357, 4.873],
                    [-0.549, 4.551],
                    [-0.806, 3.284],
                    [-1.665, 1.678],
                    [-3.284, 0.806],
                    [-4.551, 0.549],
                    [-4.874, 0.352],
                    [-5, 0],
                    [-4.874, -0.352],
                    [-4.551, -0.549],
                    [-3.284, -0.806],
                    [-1.665, -1.678],
                    [-0.806, -3.284],
                    [-0.549, -4.551],
                    [-0.357, -4.873],
                    [0, -5],
                    [0.357, -4.873],
                    [0.549, -4.551],
                    [0.806, -3.284],
                    [1.665, -1.678],
                    [3.284, -0.806],
                    [4.551, -0.549],
                    [4.874, -0.352],
                    [5, 0],
                    [4.874, 0.352]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 39,
      op: 1049,
      st: 49,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 47,
              s: [-100]
            },
            { t: 78, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 47,
              s: [275.5, 173.5, 0],
              to: [0, -7.667, 0],
              ti: [0, 7.667, 0]
            },
            { t: 79, s: [275.5, 127.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 49,
              s: [0, 0, 100]
            },
            { t: 55, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.089, -0.019],
                    [0, 0],
                    [0, 0],
                    [0.309, -0.309],
                    [0.084, -0.425],
                    [0, 0],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0.017, 0.088],
                    [0, 0],
                    [0.309, 0.309],
                    [0.43, 0.09],
                    [0, 0],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07],
                    [-0.089, 0.019],
                    [0, 0],
                    [-0.309, 0.309],
                    [-0.084, 0.425],
                    [0, 0],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [-0.017, -0.088],
                    [0, 0],
                    [-0.309, -0.309],
                    [-0.43, -0.09],
                    [0, 0],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.43, 0.09],
                    [-0.309, 0.309],
                    [0, 0],
                    [-0.017, 0.088],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [0, 0],
                    [-0.084, -0.425],
                    [-0.309, -0.309],
                    [0, 0],
                    [-0.089, -0.019],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07],
                    [0, 0],
                    [0.43, -0.09],
                    [0.309, -0.309],
                    [0, 0],
                    [0.017, -0.088],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0, 0],
                    [0.084, 0.425],
                    [0.309, 0.309],
                    [0, 0],
                    [0.089, 0.019],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07]
                  ],
                  v: [
                    [3.186, 0.384],
                    [2.299, 0.564],
                    [2.299, 0.564],
                    [1.166, 1.174],
                    [0.564, 2.299],
                    [0.384, 3.186],
                    [0.25, 3.411],
                    [0, 3.5],
                    [-0.25, 3.411],
                    [-0.384, 3.186],
                    [-0.564, 2.299],
                    [-1.166, 1.174],
                    [-2.299, 0.564],
                    [-3.186, 0.384],
                    [-3.412, 0.246],
                    [-3.5, 0],
                    [-3.412, -0.246],
                    [-3.186, -0.384],
                    [-2.299, -0.564],
                    [-1.166, -1.174],
                    [-0.564, -2.299],
                    [-0.384, -3.186],
                    [-0.25, -3.411],
                    [0, -3.5],
                    [0.25, -3.411],
                    [0.384, -3.186],
                    [0.564, -2.299],
                    [1.166, -1.174],
                    [2.299, -0.564],
                    [3.186, -0.384],
                    [3.412, -0.246],
                    [3.5, 0],
                    [3.412, 0.246]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 47,
      op: 1047,
      st: 47,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0]
            },
            { t: 42, s: [180] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [201.5, 301.5, 0],
              to: [0, -10.333, 0],
              ti: [0, 10.333, 0]
            },
            { t: 42, s: [201.5, 239.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 32,
              s: [100, 100, 100]
            },
            { t: 38, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.102, -0.022],
                    [0, 0],
                    [0, 0],
                    [0.353, -0.353],
                    [0.096, -0.485],
                    [0, 0],
                    [0.08, -0.066],
                    [0.104, 0],
                    [0.08, 0.066],
                    [0.019, 0.101],
                    [0, 0],
                    [0.353, 0.353],
                    [0.492, 0.102],
                    [0, 0],
                    [0.065, 0.08],
                    [0, 0.102],
                    [-0.065, 0.08],
                    [-0.102, 0.022],
                    [0, 0],
                    [-0.353, 0.353],
                    [-0.096, 0.485],
                    [0, 0],
                    [-0.08, 0.066],
                    [-0.104, 0],
                    [-0.08, -0.066],
                    [-0.019, -0.101],
                    [0, 0],
                    [-0.353, -0.353],
                    [-0.492, -0.102],
                    [0, 0],
                    [-0.065, -0.08],
                    [0, -0.102],
                    [0.065, -0.08]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.492, 0.102],
                    [-0.353, 0.353],
                    [0, 0],
                    [-0.019, 0.101],
                    [-0.08, 0.066],
                    [-0.104, 0],
                    [-0.08, -0.066],
                    [0, 0],
                    [-0.096, -0.485],
                    [-0.353, -0.353],
                    [0, 0],
                    [-0.102, -0.022],
                    [-0.065, -0.08],
                    [0, -0.102],
                    [0.065, -0.08],
                    [0, 0],
                    [0.492, -0.102],
                    [0.353, -0.353],
                    [0, 0],
                    [0.019, -0.101],
                    [0.08, -0.066],
                    [0.104, 0],
                    [0.08, 0.066],
                    [0, 0],
                    [0.096, 0.485],
                    [0.353, 0.353],
                    [0, 0],
                    [0.102, 0.022],
                    [0.065, 0.08],
                    [0, 0.102],
                    [-0.065, 0.08]
                  ],
                  v: [
                    [3.641, 0.439],
                    [2.627, 0.645],
                    [2.627, 0.645],
                    [1.332, 1.342],
                    [0.645, 2.627],
                    [0.439, 3.641],
                    [0.286, 3.899],
                    [0, 4],
                    [-0.285, 3.899],
                    [-0.439, 3.641],
                    [-0.645, 2.627],
                    [-1.332, 1.342],
                    [-2.627, 0.645],
                    [-3.641, 0.439],
                    [-3.899, 0.282],
                    [-4, 0],
                    [-3.899, -0.282],
                    [-3.641, -0.439],
                    [-2.627, -0.645],
                    [-1.332, -1.342],
                    [-0.645, -2.627],
                    [-0.439, -3.641],
                    [-0.285, -3.899],
                    [0, -4],
                    [0.286, -3.899],
                    [0.439, -3.641],
                    [0.645, -2.627],
                    [1.332, -1.342],
                    [2.627, -0.645],
                    [3.641, -0.439],
                    [3.899, -0.282],
                    [4, 0],
                    [3.899, 0.282]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0]
            },
            { t: 32, s: [90] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [65.5, 257.5, 0],
              to: [0, -8, 0],
              ti: [0, 8, 0]
            },
            { t: 29, s: [65.5, 209.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 19,
              s: [100, 100, 100]
            },
            { t: 25, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.076, -0.016],
                    [0, 0],
                    [0, 0],
                    [0.264, -0.264],
                    [0.072, -0.364],
                    [0, 0],
                    [0.06, -0.049],
                    [0.078, 0],
                    [0.06, 0.049],
                    [0.014, 0.076],
                    [0, 0],
                    [0.264, 0.264],
                    [0.369, 0.077],
                    [0, 0],
                    [0.049, 0.06],
                    [0, 0.077],
                    [-0.049, 0.06],
                    [-0.076, 0.016],
                    [0, 0],
                    [-0.264, 0.264],
                    [-0.072, 0.364],
                    [0, 0],
                    [-0.06, 0.049],
                    [-0.078, 0],
                    [-0.06, -0.049],
                    [-0.014, -0.076],
                    [0, 0],
                    [-0.264, -0.264],
                    [-0.369, -0.077],
                    [0, 0],
                    [-0.049, -0.06],
                    [0, -0.077],
                    [0.049, -0.06]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.369, 0.077],
                    [-0.264, 0.264],
                    [0, 0],
                    [-0.014, 0.076],
                    [-0.06, 0.049],
                    [-0.078, 0],
                    [-0.06, -0.049],
                    [0, 0],
                    [-0.072, -0.364],
                    [-0.264, -0.264],
                    [0, 0],
                    [-0.076, -0.016],
                    [-0.049, -0.06],
                    [0, -0.077],
                    [0.049, -0.06],
                    [0, 0],
                    [0.369, -0.077],
                    [0.264, -0.264],
                    [0, 0],
                    [0.014, -0.076],
                    [0.06, -0.049],
                    [0.078, 0],
                    [0.06, 0.049],
                    [0, 0],
                    [0.072, 0.364],
                    [0.264, 0.264],
                    [0, 0],
                    [0.076, 0.016],
                    [0.049, 0.06],
                    [0, 0.077],
                    [-0.049, 0.06]
                  ],
                  v: [
                    [2.731, 0.329],
                    [1.97, 0.483],
                    [1.97, 0.483],
                    [0.999, 1.006],
                    [0.483, 1.97],
                    [0.329, 2.73],
                    [0.214, 2.924],
                    [0, 3],
                    [-0.214, 2.924],
                    [-0.329, 2.73],
                    [-0.483, 1.97],
                    [-0.999, 1.006],
                    [-1.97, 0.483],
                    [-2.731, 0.329],
                    [-2.924, 0.211],
                    [-3, 0],
                    [-2.924, -0.211],
                    [-2.731, -0.329],
                    [-1.97, -0.483],
                    [-0.999, -1.006],
                    [-0.483, -1.97],
                    [-0.329, -2.73],
                    [-0.214, -2.924],
                    [0, -3],
                    [0.214, -2.924],
                    [0.329, -2.73],
                    [0.483, -1.97],
                    [0.999, -1.006],
                    [1.97, -0.483],
                    [2.731, -0.329],
                    [2.924, -0.211],
                    [3, 0],
                    [2.924, 0.211]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0]
            },
            { t: 30, s: [80] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [67.5, 54.5, 0],
              to: [0, -4.5, 0],
              ti: [0, 4.5, 0]
            },
            { t: 24, s: [67.5, 27.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 15,
              s: [100, 100, 100]
            },
            { t: 21, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.127, -0.027],
                    [0, 0],
                    [0, 0],
                    [0.441, -0.441],
                    [0.12, -0.607],
                    [0, 0],
                    [0.1, -0.082],
                    [0.13, 0],
                    [0.1, 0.082],
                    [0.024, 0.126],
                    [0, 0],
                    [0.441, 0.441],
                    [0.615, 0.128],
                    [0, 0],
                    [0.082, 0.1],
                    [0, 0.128],
                    [-0.082, 0.1],
                    [-0.127, 0.027],
                    [0, 0],
                    [-0.441, 0.441],
                    [-0.12, 0.607],
                    [0, 0],
                    [-0.1, 0.082],
                    [-0.13, 0],
                    [-0.1, -0.082],
                    [-0.024, -0.126],
                    [0, 0],
                    [-0.441, -0.441],
                    [-0.615, -0.128],
                    [0, 0],
                    [-0.082, -0.1],
                    [0, -0.128],
                    [0.082, -0.1]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.615, 0.128],
                    [-0.441, 0.441],
                    [0, 0],
                    [-0.024, 0.126],
                    [-0.1, 0.082],
                    [-0.13, 0],
                    [-0.1, -0.082],
                    [0, 0],
                    [-0.12, -0.607],
                    [-0.441, -0.441],
                    [0, 0],
                    [-0.127, -0.027],
                    [-0.082, -0.1],
                    [0, -0.128],
                    [0.082, -0.1],
                    [0, 0],
                    [0.615, -0.128],
                    [0.441, -0.441],
                    [0, 0],
                    [0.024, -0.126],
                    [0.1, -0.082],
                    [0.13, 0],
                    [0.1, 0.082],
                    [0, 0],
                    [0.12, 0.607],
                    [0.441, 0.441],
                    [0, 0],
                    [0.127, 0.027],
                    [0.082, 0.1],
                    [0, 0.128],
                    [-0.082, 0.1]
                  ],
                  v: [
                    [4.551, 0.549],
                    [3.284, 0.806],
                    [3.284, 0.806],
                    [1.665, 1.678],
                    [0.806, 3.284],
                    [0.549, 4.551],
                    [0.357, 4.873],
                    [0, 5],
                    [-0.357, 4.873],
                    [-0.549, 4.551],
                    [-0.806, 3.284],
                    [-1.665, 1.678],
                    [-3.284, 0.806],
                    [-4.551, 0.549],
                    [-4.874, 0.352],
                    [-5, 0],
                    [-4.874, -0.352],
                    [-4.551, -0.549],
                    [-3.284, -0.806],
                    [-1.665, -1.678],
                    [-0.806, -3.284],
                    [-0.549, -4.551],
                    [-0.357, -4.873],
                    [0, -5],
                    [0.357, -4.873],
                    [0.549, -4.551],
                    [0.806, -3.284],
                    [1.665, -1.678],
                    [3.284, -0.806],
                    [4.551, -0.549],
                    [4.874, -0.352],
                    [5, 0],
                    [4.874, 0.352]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0]
            },
            { t: 28, s: [100] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [275.5, 127.5, 0],
              to: [0, -12.667, 0],
              ti: [0, 12.667, 0]
            },
            { t: 24, s: [275.5, 51.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 14,
              s: [100, 100, 100]
            },
            { t: 20, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.089, -0.019],
                    [0, 0],
                    [0, 0],
                    [0.309, -0.309],
                    [0.084, -0.425],
                    [0, 0],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0.017, 0.088],
                    [0, 0],
                    [0.309, 0.309],
                    [0.43, 0.09],
                    [0, 0],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07],
                    [-0.089, 0.019],
                    [0, 0],
                    [-0.309, 0.309],
                    [-0.084, 0.425],
                    [0, 0],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [-0.017, -0.088],
                    [0, 0],
                    [-0.309, -0.309],
                    [-0.43, -0.09],
                    [0, 0],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.43, 0.09],
                    [-0.309, 0.309],
                    [0, 0],
                    [-0.017, 0.088],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [0, 0],
                    [-0.084, -0.425],
                    [-0.309, -0.309],
                    [0, 0],
                    [-0.089, -0.019],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07],
                    [0, 0],
                    [0.43, -0.09],
                    [0.309, -0.309],
                    [0, 0],
                    [0.017, -0.088],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0, 0],
                    [0.084, 0.425],
                    [0.309, 0.309],
                    [0, 0],
                    [0.089, 0.019],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07]
                  ],
                  v: [
                    [3.186, 0.384],
                    [2.299, 0.564],
                    [2.299, 0.564],
                    [1.166, 1.174],
                    [0.564, 2.299],
                    [0.384, 3.186],
                    [0.25, 3.411],
                    [0, 3.5],
                    [-0.25, 3.411],
                    [-0.384, 3.186],
                    [-0.564, 2.299],
                    [-1.166, 1.174],
                    [-2.299, 0.564],
                    [-3.186, 0.384],
                    [-3.412, 0.246],
                    [-3.5, 0],
                    [-3.412, -0.246],
                    [-3.186, -0.384],
                    [-2.299, -0.564],
                    [-1.166, -1.174],
                    [-0.564, -2.299],
                    [-0.384, -3.186],
                    [-0.25, -3.411],
                    [0, -3.5],
                    [0.25, -3.411],
                    [0.384, -3.186],
                    [0.564, -2.299],
                    [1.166, -1.174],
                    [2.299, -0.564],
                    [3.186, -0.384],
                    [3.412, -0.246],
                    [3.5, 0],
                    [3.412, 0.246]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      parent: 10,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [118, 111.513, 0], ix: 2, l: 2 },
        a: { a: 0, k: [40, 39.513, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.162, 0.224],
                    [-0.12, 0.205],
                    [0.176, 1.111],
                    [0.82, 0.769],
                    [0, 0],
                    [0, 0],
                    [0.333, 0.123],
                    [0.355, -0.015],
                    [0.321, -0.151],
                    [0.238, -0.264],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.03, -0.715],
                    [-0.523, -0.487],
                    [0, 0],
                    [0, 0],
                    [-1.082, -0.107],
                    [-0.922, 0.576],
                    [-0.313, 0.335]
                  ],
                  o: [
                    [0.163, -0.173],
                    [0.541, -0.985],
                    [-0.176, -1.111],
                    [0, 0],
                    [0, 0],
                    [-0.26, -0.243],
                    [-0.333, -0.123],
                    [-0.355, 0.015],
                    [-0.321, 0.151],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.48, 0.53],
                    [0.03, 0.715],
                    [0, 0],
                    [0, 0],
                    [0.793, 0.745],
                    [1.082, 0.107],
                    [0.387, -0.245],
                    [0, 0]
                  ],
                  v: [
                    [10.948, 9.533],
                    [11.374, 8.964],
                    [11.937, 5.73],
                    [10.401, 2.83],
                    [6.974, -0.319],
                    [-4.402, -10.779],
                    [-5.3, -11.334],
                    [-6.342, -11.498],
                    [-7.366, -11.246],
                    [-8.214, -10.617],
                    [-8.883, -9.886],
                    [-9.735, -8.972],
                    [-11.296, -7.265],
                    [-11.998, -5.323],
                    [-11.134, -3.447],
                    [-0.103, 6.689],
                    [3.669, 10.163],
                    [6.56, 11.476],
                    [9.65, 10.752],
                    [10.705, 9.878]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [14]
            },
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 42,
              s: [92]
            },
            { t: 78, s: [14] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.25, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [101.051, 80.917, 0],
              to: [31.5, -0.833, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.25, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 42,
              s: [290.051, 75.917, 0],
              to: [0, 0, 0],
              ti: [31.5, -0.833, 0]
            },
            { t: 78, s: [101.051, 80.917, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [-76.856, -72.922, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.721, -2.76],
                    [0, 0],
                    [0, 0],
                    [-0.018, -0.919],
                    [0.637, -0.665],
                    [0.134, -0.091],
                    [0.841, 0.036],
                    [0.605, 0.583],
                    [0, 0],
                    [3.309, -0.288],
                    [2.966, 1.492],
                    [1.734, 2.825],
                    [-0.018, 3.312],
                    [-3.362, 3.352],
                    [-4.755, 0.024],
                    [-3.36, -3.356],
                    [-0.002, -4.741]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.663, 0.639],
                    [0.018, 0.919],
                    [-0.103, 0.125],
                    [-0.653, 0.53],
                    [-0.841, -0.036],
                    [0, 0],
                    [-2.661, 1.982],
                    [-3.309, 0.288],
                    [-2.966, -1.492],
                    [-1.734, -2.825],
                    [0.024, -4.74],
                    [3.362, -3.352],
                    [4.756, 0.01],
                    [3.36, 3.356],
                    [-0.004, 3.25]
                  ],
                  v: [
                    [8.739, 3.595],
                    [23.436, 17.5],
                    [23.436, 17.5],
                    [24.499, 19.933],
                    [23.534, 22.405],
                    [23.175, 22.73],
                    [20.857, 23.497],
                    [18.613, 22.535],
                    [4.047, 8.761],
                    [-5.087, 12.234],
                    [-14.687, 10.393],
                    [-21.876, 3.789],
                    [-24.5, -5.599],
                    [-19.214, -18.23],
                    [-6.544, -23.5],
                    [6.129, -18.244],
                    [11.379, -5.599]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.43, -1.686],
                    [-2.96, 0.008],
                    [0, 0],
                    [-2.703, 2.701],
                    [0, 3.815],
                    [2.703, 2.701],
                    [3.827, 0.009],
                    [2.602, -2.229],
                    [0.757, -2.853],
                    [-1.021, -2.77]
                  ],
                  o: [
                    [2.43, 1.686],
                    [0, 0],
                    [3.827, -0.009],
                    [2.703, -2.701],
                    [0, -3.815],
                    [-2.703, -2.701],
                    [-3.431, -0.007],
                    [-2.261, 1.905],
                    [-0.757, 2.853],
                    [1.021, 2.77]
                  ],
                  v: [
                    [-14.825, 6.248],
                    [-6.544, 8.825],
                    [-6.544, 8.825],
                    [3.653, 4.593],
                    [7.874, -5.583],
                    [3.653, -15.759],
                    [-6.544, -19.991],
                    [-15.897, -16.548],
                    [-20.533, -9.237],
                    [-20.127, -0.598]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'mm',
              mm: 1,
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    }
  ],
  markers: []
};
